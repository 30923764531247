<template>
  <v-app class="pt-15">
    <customize-header homeRouter="/customers/home"
                      homeTitle="大客户定制"
                      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
</template>

<script>

export default {
  name: 'ARcreativeMarketingIndex',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/customers/home",
        children: []
      },
      {
        title: "解决方案",
        router: "/customers/customersSolution",
        children: []
      },
      // {
      //   title: "案例",
      //   router: "/customers/customersCase",
      //   children: []
      // }
    ],
  }),
  mounted() {
 
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>

</style>